import Button from 'react-bootstrap/Button';

const FCFormActivate = () => {

	return (

		<>
			<h3 className="text-uppercase mt-4 mb-4">Activation Form</h3>
          	<div>Please check your email for the acitivation code.</div>

          	<div className="bs-callout bs-callout-warning d-none">
                <h4> </h4>
                <p> </p>
          	</div>

          	<div className="bs-callout bs-callout-info d-none">
                <h4> </h4>
                <p> </p>
          	</div>

        
          	<form method="post" id="activationForm" name="activationForm" data-parsley-validate="" autoComplete="false" action="index.php">
            	<input type="text1" id="key" className="mb-3" name="key" placeholder="Key" autoFocus required autoComplete="false" />
            	<div className="d-grid gap-2 m-5 my-3 ">
            		<Button id="btnActivate" type="submit" className="my-1">Activate</Button>
            		<Button id="btnResendKey" variant="warning" className="w-50 mx-auto" type="button">Resend Key</Button>
            	</div>
          	</form>
		</>

	)

}

export default FCFormActivate