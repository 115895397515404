import Button from 'react-bootstrap/Button';

const FCFormRegister = () => {

	return (

		<>
			<h3 id="formTitle" className="mt-4 mb-4">Registration Form</h3>

  			<div className="bs-callout bs-callout-warning d-none">   
  			</div>

          	<div className="bs-callout bs-callout-info d-none">
          	</div>

    	
    		<form method="post" id="registrationForm" name="registrationForm" data-parsley-validate="" autoComplete="false" action="activationForm.php">
    			<input type="text" id="first" className="" name="first" placeholder="First Name" autoFocus required="" required />
      			<input type="text" id="last" className="" name="last" placeholder="Last Name" required />
				<input type="email" id="email" className="" name="email" placeholder="Email" required />
    			<div>
      				<i id="password-show" className="fas fa-eye fa-lg position-absolute me-n25rem mt-4"></i>
      				<input type="password" id="password" className="" name="password" placeholder="Password" required />
      
    			</div>
    			<div className="mb-3">
      				<i id="confirm-password-show" className="fas fa-eye fa-lg position-absolute me-n25rem mt-4"></i>
      				<input type="password" id="confirm-password" className="" name="confirm-password" placeholder="Confirm Password" required />
      
    			</div>
    			<div className="d-grid gap-2 m-5 mt-3">
					<Button id="btnRegister" type="submit">Register</Button>
				</div>
	    	</form>

		</>

	)

}

export default FCFormRegister