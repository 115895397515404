import Button from 'react-bootstrap/Button';

const FCFormSignIn = () => {

	const submitHandler = (event) => {
		event.preventDefault()

		//check form
		//send 
	}

	return (

		<>
			<h3 className="text-uppercase mt-4 mb-4"> Signin Form </h3>
    
			<div className="bs-callout bs-callout-warning d-none">
				<h4> </h4>
				<p> </p>
			</div>

			<div className="bs-callout bs-callout-info d-none">
				<h4> </h4>
				<p> </p>
			</div>

			<form method="post" id="signinForm" name="signinForm" data-parsley-validate="" autoComplete="false" onSubmit={submitHandler}>
				<input type="email" id="email" name="email" placeholder="Email" required autoComplete="off" />
				<div className="mb-3">
						<div id="password-show"><span className="material-icons">visibility</span></div>
						<input type="password" id="password" name="password" placeholder="Password" required autoComplete="off" />
				</div>
				<div className="d-grid gap-2 m-5 mt-3">
					<Button type="submit">Sign In</Button>
				</div>
			</form>
		</>

	)

}

export default FCFormSignIn