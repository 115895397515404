import Button from 'react-bootstrap/Button';

const FCFormForgotPassword = () => {

	return (

		<>
			
			<h3 className="text-uppercase mt-4 mb-4"> Reset Password </h3>

			<div className="bs-callout bs-callout-warning d-none">
				<h4> </h4>
				<p> </p>
			</div>

			<div className="bs-callout bs-callout-info d-none">
				<h4> </h4>
				<p> </p>
			</div>

			<form method="post" id="signinForm" name="signinForm" data-parsley-validate="" autoComplete="false" action="route.php">
				
				<div className="mb-3">
					<div id="password-show"><span className="material-icons">visibility</span></div>
					<input type="password" id="password" name="password" placeholder="Password" required autoComplete="off" />
				</div>
				<div className="d-grid gap-2 m-5 mt-3">
					<Button id="btnSubmit" type="submit">Reset Password</Button>
				</div>
			</form>
    
  				

		</>

	)

}

export default FCFormForgotPassword