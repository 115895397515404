import FCNavbarComponent from './components/FCNavbarComponent'
import FCHomepageHome from './components/FCHomepageHome'
import FCHomepageFeatures from './components/FCHomepageFeatures'
import FCHomepagePricing from './components/FCHomepagePricing'
import FCHomepageAboutUs from './components/FCHomepageAboutUs'
import FCHomepageFooter from './components/FCHomepageFooter'
import './App.scss'

function App() {
  return (
    <>
      <FCNavbarComponent />
      <FCHomepageHome />
      <FCHomepageFeatures />
      <FCHomepagePricing />
      <FCHomepageAboutUs />
      <FCHomepageFooter />
    </>
  )
}

export default App
