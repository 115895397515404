import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import FCFormSignIn from './forms/FCFormSignIn'
import FCFormRegister from './forms/FCFormRegister'
import FCFormForgotPassword from './forms/FCFormForgotPassword'
import FCFormActivate from './forms/FCFormActivate'

const FCHomepageHome = () => {

	const [page, setPage] = useState('signin')

	function handleClick(page){
		setPage(page)
	}

	
	return (

		<>
			<section id="home" className="cover-1">
        		<div className="mx-1 mt-5 py-md-5">
          			<div className="row">
          				<div id="formContent" className="col-12 col-lg-6 h-100 mt-3 mb-5 pt-3 mx-auto custom-shadow">
	            			{
	            				page === "signin" && 
	            				<>
		            				<FCFormSignIn />
		            				<div className="formFooter">
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('register')} variant="secondary" type="button">Register Now</Button>
		            					</div>
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('forgotpassword')} variant="secondary" type="button">Forgot Password?</Button>
		            					</div>
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('activate')} variant="secondary" type="button">Activate Account / Resend Key</Button>
		            					</div>
		            				</div>
		            			</>
	            			}
	            			{
	            				page === "register" && 
	            				<>
		            				<FCFormRegister />
		            				<div className="formFooter">
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('signin')} variant="secondary" type="button">Sign In</Button>
		            					</div>
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('forgotpassword')} variant="secondary" type="button">Forgot Password?</Button>
		            					</div>
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('activate')} variant="secondary" type="button">Activate Account / Resend Key</Button>
		            					</div>
		            				</div>
		            			</>
	            			}
	            			{
	            				page === "forgotpassword" && 
	            				<>
		            				<FCFormForgotPassword />
		            				<div className="formFooter">
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('signin')} variant="secondary" type="button">Sign In</Button>
		            					</div>
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('register')} variant="secondary" type="button">Register Now</Button>
		            					</div>
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('activate')} variant="secondary" type="button">Activate Account / Resend Key</Button>
		            					</div>
		            				</div>
		            			</>
	            			}
	            			{
	            				page === "activate" && 
	            				<>
		            				<FCFormActivate />
		            				<div className="formFooter">
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('signin')} variant="secondary" type="button">Sign In</Button>
		            					</div>
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('register')} variant="secondary" type="button">Register Now</Button>
		            					</div>
		            					<div className="d-grid gap-2 m-2">
		            						<Button onClick={()=>handleClick('forgotpassword')} variant="secondary" type="button">Forgot Password?</Button>
		            					</div>
		            				</div>
		            			</>
	            			}

	            			
			  			</div>
            		
            			<div className="col-12 col-lg-6 p-5 text-center">
              				<h3 className="jumbotron-heading text-white">Still in Development</h3>
              				<p className="lead">We are still in development stages.  At this time there is no support.  Do not use real data.  Use this system at your own risk.</p>
              				
              				<Button onClick={()=>handleClick('register')} variant="success" size="lg">Register Now</Button>	
            			</div>
          			</div>
        		</div>
      		</section>
		</>

	)

}

export default FCHomepageHome