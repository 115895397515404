const FCHomepageFooter = () => {

	return (

		<>	
			<footer id="contactus" className="footer-4 py-5">
				<div className="row contact-details mb-2">
					<div className="col-12 text-center">
						<h3>Ask us a question</h3>
					</div>
					<div className="col-12 text-center">
						<h4 className="pt-4">Email</h4>
						<p><a href="mailto:info@funeralcomplete.com">info@funeralcomplete.com</a></p>
					</div>
				</div>
				<div className="row">
					<div className="col-12 text-center my-3">
						<span className="material-icons mi-16">copyright</span> All rights reserved by FuneralComplete.com
					</div>
				</div>
			</footer>
		</>

	)
}

export default FCHomepageFooter