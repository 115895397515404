import Container from 'react-bootstrap/Container'

const FCHomepagePricing = () => {

	return (

		<>
			<section id="pricing" className="bg-light">
		        <Container className="text-center p-5">
		            <div className="row text-center">
		            	<div className="col-12">
			        	    <h2 className="text-dark w-100">Pricing</h2>	
						    <div className="divider"></div>
						</div>
		            </div>
		            <div className="row">
		            	<div className="col-12 col-lg-4">
			            	<div className="card mt-5">
			                    <div className="card-header">
			                        <h3>FREE</h3>
			                    </div>
			                    <div className="card-body">
			                        <p>Try us out for 30 days and see what you think.</p> 
			                        <p>1 Admin User and 4 Employee Users</p>
			                        <div className="features">
			                            <h4><span className="feature">Duration</span> : <span className="value">30 Days</span></h4>
			                            <h4><span className="feature">Storage</span> : <span className="value">1GB</span></h4>
			                        </div>
			                        <div className="price">
			                            <h4>$0</h4>
			                        </div>
			                    </div>
			                    <div className="card-footer">
			                    	<button className="btn btn-block btn-outline-primary" type="submit">Try It Before You Buy It</button>
			                    </div>
			            	</div>
			            </div>
			            <div className="col-12 col-lg-4">
		                    <div className="card mt-5">
		                        <div className="card-header">
		                            <h3>MONTHLY</h3>
		                        </div>
		                        <div className="card-body">
		                            <p>Pay by the month.  Quit anytime.</p>
			                        <div className="features">
			                            <h4><span className="feature">Duration</span> : <span className="value">30 Days</span></h4>
			                            <h4><span className="feature">Storage</span> : <span className="value">10GB</span></h4>
			                        </div>
			                        <div className="price">
			                            <h4>(Undecided) a month per user.</h4>
			                        </div>
		                        
		                        </div>
		                        <div className="card-footer">
		                        	<button className="btn btn-block btn-outline-primary" type="submit">BUY NOW</button>
		                        </div>
		                	</div>
		                </div>
		                <div className="col-12 col-lg-4">
		                	<div className="card mt-5">
		                        <div className="card-header">
		                            <h3>YEARLY</h3>
		                        </div>
		                        <div className="card-body">
		                            <p>Make an annual payment and save.</p>
			                        <div className="features">
			                            <h4><span className="feature">Duration</span> : <span className="value">1 Year</span></h4>
			                            <h4><span className="feature">Storage</span> : <span className="value">10GB</span></h4>
			                        </div>
			                        <div className="price">
			                            <h4>(Undecided) a year per user.</h4>
			                        </div>
		                        </div>
		                        <div className="card-footer">
		                        	<button className="btn btn-block btn-outline-primary" type="submit">BUY NOW</button>
		                        </div>
		                	</div>
		                </div>
		            </div>
		        </Container>
		    </section>
		</>

	)

}

export default FCHomepagePricing