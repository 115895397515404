import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import MenuIcon from '@mui/icons-material/Menu'

const appName = "FC Dev"
const appVersion = "0.5"
const expand = "md"

const FCNavbarComponent = () => {



	return (
		<>
			<Navbar variant="dark" expand={expand} fixed="top" className="bg-dark">
				<Container fluid>
					<Navbar.Brand href="index.html#">{appName} {appVersion}</Navbar.Brand>

					<Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} >
						<MenuIcon color="primary"/>
					</Navbar.Toggle>

					<Navbar.Offcanvas
		              id={`offcanvasNavbar-expand-${expand}`}
		              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
		              placement="start"
            	>
              	<Offcanvas.Header className="bg-dark" closeButton closeVariant="white">
                	<Navbar.Brand href="index.html#">{appName} {appVersion}</Navbar.Brand>
                	
              	</Offcanvas.Header>
              	<Offcanvas.Body className="bg-dark">
                	<Nav className="justify-content-end flex-grow-1 pe-3">
                  		<Nav.Link href="index.html#home">Home</Nav.Link>
                  		<Nav.Link href="index.html#aboutus">About</Nav.Link>
                  		<Nav.Link href="index.html#pricing">Pricing</Nav.Link>
                  		<Nav.Link href="index.html#contactus">Contact</Nav.Link>
                	</Nav>
              	</Offcanvas.Body>
            </Navbar.Offcanvas>
				</Container>
			</Navbar>
		</>
	)
}

export default FCNavbarComponent