import Container from 'react-bootstrap/Container'

const FCHomepageFeatures = () => {

	return(

		<>
			<section className="features-4 text-center">
				<Container>
					<div className="row justify-center">
						<div className="col-md-8 text-center" >
							<h2>Features</h2>	
							<div className="divider"></div>				
						</div>

					</div>
					<div className="row">
						<div className="col-md-3 col-feature">
							<div className="rounded-circle justify-center">
								<span className="material-icons mi-36">brush</span>
							</div>
							<h4>Clean Design</h4>
							<p>Focusing on simplicity was our first priority.  Why make things harder?</p>
						</div>
						<div className="col-md-3 col-feature">
							<div className="rounded-circle justify-center">
								<span className="material-icons mi-36">devices</span>
							</div>
							<h4>Responsive</h4>
							<p>Mobile design has never been more important.  Raise your hand if you don't have a phone.</p>
						</div>
						<div className="col-md-3 col-feature">
							<div className="rounded-circle justify-center">
								<span className="material-icons mi-36">dashboard</span>
							</div>
							<h4>Bootstrap 4</h4>
							<p>Build on Bootstrap 4 and the newest user interface tech available</p>
						</div>
						<div className="col-md-3 col-feature">
							<div className="rounded-circle justify-center">
								<span className="material-icons mi-36">accessibility_new</span>
							</div>
							<h4>Built for You</h4>
							<p>We have been in your shoes and know what you need.</p>
						</div>
					</div>
				</Container>
			</section>
		</>

	)
}

export default FCHomepageFeatures

