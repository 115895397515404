import Container from 'react-bootstrap/Container'

const FCHomepageAboutUs = () => {
	return (
		<>
			<section id="aboutus" className="content-4">
				<Container className="px-5">
					<div className="row justify-center mt-5">
						<div className="col-md-6 pl-5 pr-5 text-center" >
							<img className="img-fluid" src="img/writing.jpg" alt="writing" />
						</div>
						<div className="col-md-6 text-center" >
							<h3 className="mt-3 mb-3 text-uppercase">We are professionals who know how to code.</h3>
							<p className="lead mt-4 mb-5">With experience in the industry we know what you have to go through everyday.  Arrangements, Logistics, Billing, Regulatory, State, Merchandise, Inventory....the list goes on doesn't it. </p>
							
							<div className="row">
								<div className="col-md-6 col-feature mb-4">
									<h4 className="mb-3">Clean Design</h4>
									<p>Let us help you get it done smoothly.</p>
								</div>
								<div className="col-md-6 col-feature mb-4">
									<h4 className="mb-3">Bootstrap 4</h4>
									<p>The future of User Interface.  Its here!</p>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</section>
		</>
	)
}

export default FCHomepageAboutUs